<template>
    <div class="key-ingredients main-wrapper">
        <div class="nav-wrapper" v-if="$store.getters.getDevice === 'web'" :style="{'background-image': `url(${top})`}">
            <div class="btn-column">
                <button
                    class="btn-item"
                    v-for="btn in btnLabelList"
                    :key="btn.index"
                    @click="onTopBtnClick(btn.index)">
                {{ $store.getters.getLanguage === '' ? btn.name : btn.name_EN}}
                </button>
            </div>
        </div>
        <div class="nav-wrapper-mobile" :class="{'collapse': isCollapse}" v-else :style="{'background-image': `url(${top})`}">
            <div class="btn-wrapper">
                <div class="btn-item" 
                    v-for="btn in btnLabelList"
                    :key="btn.index"
                    :id="`btn${btn.index}`"
                    @click="onTopBtnClick(btn.index)">
                    {{ $store.getters.getLanguage === '' ? btn.name : btn.name_EN}}
                </div>
            </div>
            <div class="btn" @click="isCollapse = !isCollapse">
                <span v-if="isCollapse">
                    {{ $store.getters.getLanguage === '' ? '展开全部' : 'Expand'}}
                </span>
                <span v-else>
                    {{ $store.getters.getLanguage === '' ? '收起' : 'Collapse'}}
                </span>
                <i :class="[ isCollapse ? 'el-icon-arrow-down' : 'el-icon-arrow-up' ]"></i>
            </div>
        </div>
        <!-- <custom-title :title="'Vital Ergo®麦角硫因'" 
            :level="'first'" 
            :link="'https://www.sciencedirect.com/science/article/pii/S0925443911002201'">
        </custom-title>
        <custom-title :title="'质量保证'" :level="'second'"></custom-title>
        <custom-title :title="'生产工艺-发酵法'" :level="'second'"></custom-title>
        <custom-title :title="'γ-谷氨酰半胱氨酸(GGC)'" 
            :level="'first'" 
            :link="'https://www.sciencedirect.com/science/article/abs/pii/S0946672X18304796'">
        </custom-title>
        <custom-title :title="'产品介绍'" :level="'second'"></custom-title>
        <custom-title :title="'主要功效'" :level="'second'"></custom-title>
        <custom-title :title="'My BHB®BHB'" 
            :level="'first'" 
            :link="'https://www.sciencedirect.com/science/article/pii/S2666149723000099'">
        </custom-title>
        <custom-title :title="'产品介绍'" :level="'second'"></custom-title>
        <custom-title :title="'主要功效'" :level="'second'"></custom-title>
        <custom-title :title="'我们的优势'" :level="'second'"></custom-title>
        <custom-title :title="'γ-氨基丁酸(GABA)'" 
            :level="'first'" 
            :link="'https://onlinelibrary.wiley.com/doi/10.1111/dmcn.13746'">
        </custom-title>
        <custom-title :title="'产品介绍'" :level="'second'"></custom-title>
        <custom-title :title="'主要功效'" :level="'second'"></custom-title>
        <custom-title :title="'Sweet Beta®β-丙氨酸'" 
            :level="'first'" 
            :link="'https://www.sciencedirect.com/science/article/pii/S2213231718305408'">
        </custom-title>
        <custom-title :title="'产品功效与应用'" :level="'second'"></custom-title>
        <custom-title :title="'我们的优势'" :level="'second'"></custom-title>
        <custom-title :title="'产品规格'" :level="'second'"></custom-title>
        <custom-title :title="'依克多因'" 
            :level="'first'" 
            :link="'https://www.sciencedirect.com/science/article/pii/S0006349513041076'">
        </custom-title>
        <custom-title :title="'主要功效'" :level="'second'"></custom-title>
        <custom-title :title="'产品应用'" :level="'second'"></custom-title>
        <custom-title :title="'我们的优势'" :level="'second'"></custom-title>
        <custom-title :title="'羟基依克多因'" 
            :level="'first'" 
            :link="'https://www.sciencedirect.com/science/article/pii/S0014579305009191'">
        </custom-title>
        <custom-title :title="'产品介绍'" :level="'second'"></custom-title>
        <custom-title :title="'主要功效'" :level="'second'"></custom-title>
        <custom-title :title="'我们的优势'" :level="'second'"></custom-title>
        <custom-title :title="'肌醇'" 
            :level="'first'" 
            :link="'https://www.sciencedirect.com/science/article/abs/pii/S0044848623000777'">
        </custom-title>
        <custom-title :title="'产品介绍'" :level="'second'"></custom-title>
        <custom-title :title="'主要功效'" :level="'second'"></custom-title>
        <custom-title :title="'产品应用'" :level="'second'"></custom-title>
        <custom-title :title="'神经酸'" 
            :level="'first'" 
            :link="'https://www.sciencedirect.com/science/article/abs/pii/S0944711323002726'">
        </custom-title>
        <custom-title :title="'产品介绍'" :level="'second'"></custom-title>
        <custom-title :title="'主要功效'" :level="'second'"></custom-title>
        <custom-title :title="'产品应用'" :level="'second'"></custom-title>
        <custom-title :title="'L-茶氨酸'" 
            :level="'first'" 
            :link="'https://www.mdpi.com/2072-6643/11/10/2362'">
        </custom-title>
        <custom-title :title="'产品介绍'" :level="'second'"></custom-title>
        <custom-title :title="'主要功效'" :level="'second'"></custom-title>
        <custom-title :title="'产品应用'" :level="'second'"></custom-title>
        <custom-title :title="'甘油二酯'" 
            :level="'first'" 
            :link="'https://www.tandfonline.com/doi/abs/10.1080/10408398.2019.1650001'">
        </custom-title>
        <custom-title :title="'产品介绍'" :level="'second'"></custom-title>
        <custom-title :title="'主要功效'" :level="'second'"></custom-title>
        <custom-title :title="'产品应用'" :level="'second'"></custom-title> -->

        <div class="ki-card-wrapper">
            <div class="ki-card" v-for="card in cardList" :key="card.index">
                <div :id="'card' + card.index" class="ki-card-title" :class="[$store.getters.getLanguage === '' ? '' : 'EN']">
                    <h1>{{ $store.getters.getLanguage === '' ? card.title : card.title_EN}}</h1>
                    <a :href="card.href" target="_blank">
                        {{ $store.getters.getLanguage === '' ? '相关文献阅读' : 'Related literature reading' }}
                        <img src="../assets/images/right.webp" />
                    </a>
                </div>
                <img :src="card.imgUrl" />
            </div>
        </div>
        <img id="bottom" :src="bottom" />
    </div>
</template>

<script>
import { getCosUrl } from '../utils';
// import customTitle from '../components/customTitle.vue';
export default {
    name: "key-ingredients",
    //   components: {customTitle},
    data() {
        return {
            btnLabelList: [
                {
                    name: "Vital Ergo™麦角硫因",
                    name_EN: "Vital Ergo™EGT",
                    index: 1,
                },
                // {
                //     name: "谷氨酰半胱氨酸(GGC)",
                //     name_EN: "γ-Glutamyl cysteine (GGC)",
                //     index: 2,
                // },
                {
                    name: "My BHB™BHB",
                    name_EN: "My BHB™BHB",
                    index: 3,
                },
                {
                    name: "γ-氨基丁酸(GABA)",
                    name_EN: "γ-Aminobutyric(GABA)",
                    index: 4,
                },
                {
                    name: "Sweet Beta®β-丙氨酸",
                    name_EN: "Sweet Beta®(β-alanine)",
                    index: 5,
                },
                {
                    name: "依克多因",
                    name_EN: "Ectoine",
                    index: 6,
                },
                {
                    name: "羟基依克多因",
                    name_EN: "Hydroxyexdoine",
                    index: 7,
                },
                {
                    name: "L-茶氨酸",
                    name_EN: "L-Theanine",
                    index: 10,
                },

                // {
                //     name: "肌醇",
                //     name_EN: "Inositol",
                //     index: 8,
                // },
                // {
                //     name: "神经酸",
                //     name_EN: "Nervonic acid",
                //     index: 9,
                // },
                // {
                //     name: "甘油二酯",
                //     name_EN: "Diacylglycerol",
                //     index: 11,
                // },
                {
                    name: "更多产品",
                    name_EN: "More Product",
                    index: "bottom",
                },
            ],
            cardList: [
                {
                    title: "Vital Ergo™麦角硫因",
                    title_EN: "Vital Ergo™EGT",
                    imgUrl: "",
                    imgKey: "/keyingredient/1.webp",
                    index: 1,
                    href: "https://www.sciencedirect.com/science/article/pii/S0925443911002201",
                },
                // {
                //     title: "谷氨酰半胱氨酸(GGC)",
                //     title_EN: "γ-Glutamyl cysteine (GGC)",
                //     imgUrl: "",
                //     imgKey: "/keyingredient/2.webp",
                //     index: 2,
                //     href: "https://www.sciencedirect.com/science/article/abs/pii/S0946672X18304796",
                // },
                {
                    title: "My BHB™BHB",
                    title_EN: "My BHB™BHB",
                    imgUrl: "",
                    imgKey: "/keyingredient/3.webp",
                    index: 3,
                    href: "https://www.sciencedirect.com/science/article/pii/S2666149723000099",
                },
                {
                    title: "γ-氨基丁酸(GABA)",
                    title_EN: "γ-Aminobutyric(GABA)",
                    imgUrl: "",
                    imgKey: "/keyingredient/4.webp",
                    index: 4,
                    href: "https://onlinelibrary.wiley.com/doi/10.1111/dmcn.13746",
                },
                {
                    title: "Sweet Beta®β-丙氨酸",
                    title_EN: "Sweet Beta®(β-alanine)",
                    imgUrl: "",
                    imgKey: "/keyingredient/5.webp",
                    index: 5,
                    href: "https://www.sciencedirect.com/science/article/pii/S2213231718305408",
                },
                {
                    title: "依克多因",
                    title_EN: "Ectoine",
                    imgUrl: "",
                    imgKey: "/keyingredient/6.webp",
                    index: 6,
                    href: "https://www.sciencedirect.com/science/article/pii/S0006349513041076",
                },
                {
                    title: "羟基依克多因",
                    title_EN: "Hydroxyexdoine",
                    imgUrl: "",
                    imgKey: "/keyingredient/7.webp",
                    index: 7,
                    href: "https://www.sciencedirect.com/science/article/pii/S0014579305009191",
                },
                {
                    title: "L-茶氨酸",
                    title_EN: "L-Theanine",
                    imgUrl: "",
                    imgKey: "/keyingredient/10.webp",
                    index: 10,
                    href: "https://www.mdpi.com/2072-6643/11/10/2362",
                },


                // {
                //     title: "肌醇",
                //     title_EN: "Inositol",
                //     imgUrl: "",
                //     imgKey: "/keyingredient/8.webp",
                //     index: 8,
                //     href: "https://www.sciencedirect.com/science/article/abs/pii/S0044848623000777",
                // },
                // {
                //     title: "神经酸",
                //     title_EN: "Nervonic acid",
                //     imgUrl: "",
                //     imgKey: "/keyingredient/9.webp",
                //     index: 9,
                //     href: "https://www.sciencedirect.com/science/article/abs/pii/S0944711323002726",
                // },

                // {
                //     title: "甘油二酯",
                //     title_EN: "Diacylglycerol",
                //     imgUrl: "",
                //     imgKey: "/keyingredient/11.webp",
                //     index: 11,
                //     href: "https://www.tandfonline.com/doi/abs/10.1080/10408398.2019.1650001",
                // },
            ],
            top: "",
            bottom: "",
            isCollapse: true
        };
    },
    watch: {
        "$route.params.isJumped": {
            handler(newVal) {
                if (newVal) document.querySelector("#bottom")?.scrollIntoView();
            },
        },        
        "$store.state.device": {
            handler(newVal, oldVal){
                if(newVal != oldVal){
                    this.initCardList();
                    this.top = getCosUrl("/keyingredient/top-bar.webp");
                    this.bottom = getCosUrl("/keyingredient/bottom.webp");
                }
            },
            deep: true
        },
        "$store.state.language": {
            handler(newVal, oldVal){
                if(newVal != oldVal){
                    this.initCardList();
                    this.bottom = getCosUrl("/keyingredient/bottom.webp");
                }
            },
            deep: true
        }
    },
    mounted() {
        this.top = getCosUrl("/keyingredient/top-bar.webp");
        this.bottom = getCosUrl("/keyingredient/bottom.webp");
        this.initCardList();
        const archor = this.$route.params.archor;
        if(archor){
            document.querySelector('#' + archor).scrollIntoView();
        }
    },
    methods: {
        initCardList(){
            const imgList = getCosUrl(this.cardList.map(item => { return item.imgKey }));
            this.cardList.forEach((element, index) => {
                element.imgUrl = imgList[index];
            })
        },
        onTopBtnClick(index) {
            this.isCollapse = true;
            const card = document.querySelector(index === "bottom" ? "#bottom" : "#card" + index);
            const btn = document.querySelector("#btn" + index);
            card.scrollIntoView({ behavior: this.$store.getters.getDevice === 'web' ? 'smooth': 'auto'});
            if(this.$store.getters.getDevice === 'mobile'){
                setTimeout(() => {
                    document.querySelector('.btn-wrapper').scrollTo({ left: btn.offsetLeft - 100, behavior: "smooth"})
                }, 300)
            }
        },
    }
};
</script>

<style lang="less">
div.key-ingredients {
  flex-direction: column;
  display: flex;
  color: #000;
  font-family: Source Han Sans CN;
  div.nav-wrapper {
    // background-image: url("../assets/images/keyingredients/top-2.webp");
    background-repeat: no-repeat;
    justify-content: center;
    background-size: cover;
    flex-direction: column;
    align-items: center;
    position: relative;
    overflow: hidden;
    height: 39.8rem;
    display: flex;
    width: 100%;
    div.btn-column {
      width: 97rem;
      display: flex;
      flex-wrap: wrap;
      justify-content: left;
      margin-bottom: 2.4rem;
      .btn-item {
        transition: all 0.3s;
        text-decoration: none;
        border-radius: 8rem;
        border: 1px solid rgba(255, 255, 255, 0.6);
        background: rgba(0, 0, 0, 0.15);
        color: #fff;
        font-size: 1.8rem;
        font-weight: 500;
        padding: 1.8rem 3.2rem;
        margin: 0 2.4rem 2rem 0;
      }
      .btn-item:last-child {
        margin-right: 0;
      }
      .btn-item:hover {
        background: rgba(0, 105, 173, 0.6);
        transform: scale(1.1);
        cursor: pointer;
      }
    }
    div.btn-column:last-child {
      margin-bottom: 0;
    }
  }
  div.nav-wrapper-mobile{
    background-repeat: no-repeat;
    background-size: cover;
    transition: all .3s;
    max-height: 15.2vw;
    position: fixed;
    height: 15.2vw;
    top: 11.7333vw;
    width: 100%;
    z-index: 99;
    .btn-wrapper{
        margin-right: 21.0667vw;
        padding: 0 4.2667vw;
        // padding: 0 0 0 4.2667vw;
        align-items: center;
        overflow-x: auto;
        height: inherit;
        display: flex;
        &::-webkit-scrollbar{
            display: none;
        }
        .btn-item{
            border: 0.2667vw solid rgba(255, 255, 255, 0.6);
            background: rgba(0, 0, 0, 0.15);
            backdrop-filter: blur(1.384vw);
            padding: 2.1333vw 3.2vw;
            border-radius: 13.84vw;
            transition: all .3s;
            margin-right: 1.6vw;
            align-items: center;
            white-space: nowrap;
            font-weight: 500;
            color: #fff;
            display: flex;
            &:hover{
                background: rgba(0, 105, 173, 0.6);
                transform: scale(1.1);
                margin: 0 3.2vw;
            }
        }
    }
    .btn{
        box-shadow: 0px 0px 4.8vw 1.6vw rgba(255, 255, 255, 0.6);
        justify-content: center;
        background: #fff;
        align-items: center;
        position: absolute;
        font-size: 3.2vw;
        width: 22.6667vw;
        font-weight: 500;
        height: 15.2vw;
        display: flex;
        right: 0;
        top: 0;
        i{
            margin-left: 1.0667vw;
            font-size: 2.6667vw;
            font-weight: 500;
        }
    }
    &:not(.collapse){
        max-height: 76vw;
        height: 76vw;
        .btn-wrapper{
            margin-top: 8vw;
            margin-right: 0;
            flex-wrap: wrap;
            height: 44.8vw;
        }
        .btn{
            background: rgba(255, 255, 255, 0.8);
            backdrop-filter: blur(1.3333vw);
            height: 15.2vw;
            top: initial;
            width: 100%;
            bottom: 0;
            left: 0;
        }
    }
  }
  div.ki-card-wrapper {
    @media screen and (min-device-width: 1200px) {
        margin: 21rem auto 31.7rem;
        width: 120rem;
    }
    @media screen and (max-device-width: 1199px) {
        margin: 15.3vw auto 8vw;
        width: 91.7333vw;
    }
    justify-content: center;
    flex-direction: column;
    align-items: center;
    display: flex;
    div.ki-card-title {
        display: flex;
        justify-content: space-between;
        h1 {
            font-weight: normal;
            margin: 0;
        }
        a {
            text-decoration: none;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: #fff;
            color: #000;
        }
        @media screen and (min-device-width: 1200px) {
            margin-bottom: 5.4rem;
            margin-top: 8.4rem;
            h1{
                font-size: 5.4rem;
            }
            a{
                border: 1px solid rgba(0, 0, 0, 0.2);
                padding: 0.9rem 2.2rem 1rem 2.2rem;
                border-radius: 11.6rem;
                margin-bottom: 1.6rem;
                margin-top: 1.7rem;
                font-size: 1.8rem;
                img{
                    width: 1.2rem !important;
                    margin-left: 0.9rem;
                }
            }
        }
        @media screen and (max-device-width: 1199px) {
            margin: 8vw 0 5.3333vw 0;
            h1{
                font-size: 5.8667vw;
            }
            a{
                font-size: 3.2vw;
                img{
                    margin-left: 1.0667vw;
                    width: 1.6vw;
                }
            }
            &.EN{
                h1{
                    font-size: 4.5vw;
                }
                a{
                    transform: scale(0.8);
                    margin-right: -3.4vw;
                    font-size: 2.4vw;
                }
            }
        }
    }
  }
}
</style>
